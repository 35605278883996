
  import { Component, Vue } from 'vue-property-decorator'
  import { CourseInterface, CourseFormat, CategoryInterface } from '@/interface/BaseInterface'
  import { courseFormat } from '@/config'
  import getApi from '@/api'
  import CategoryApi from '@/modules/course/api/CategoryApi'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'
  import CourseApi from '@/modules/course/api/CourseApi'
  import { namespace } from 'vuex-class'
  import { cloneDeep } from 'lodash'

  const categoryApi: CategoryApi = getApi('category')
  const courseApi: CourseApi = getApi('course')
  const tCourseDetail = namespace('tCourseDetail')

  @Component({ components: { BaseEditor } })
  export default class CourseForm extends Vue {
    @tCourseDetail.Action('setCourseActive') setCourseActive!: (data: CourseInterface) => void

    format: CourseFormat[] = courseFormat

    categories: CategoryInterface[] = []

    data: CourseInterface = {
      _id: '',
      fullname: ''
    }

    isLoading = false

    async init() {
      try {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        this.data = await courseApi.getById(this.courseId)
        this.categories = await categoryApi.all({})
        this.setCourseActive(this.data)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    async handleSubmit() {
      try {
        // @ts-ignore
        this.$refs.formActive.validate(async (value: boolean) => {
          if (value) {
            const a = cloneDeep(this.data)
            delete a.sections
            delete a._id
            const data = await courseApi.update(this.data._id, a)
            this.$emit('edit', data)
            this.$message.success(this.$t('editSuccess') as string)
            return Promise.resolve()
          } else {
            return Promise.reject(false)
          }
        })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    handleChange(text: string) {
      this.data.summary = text
    }

    handleChangeEmailFormat(text: string) {
      this.data.emailFormat = text
    }

    async mounted() {
      await this.init()
      if (this.$refs.editor) {
        // @ts-ignore
        this.$refs.editor.setData(this.data.summary)
      }
      if (this.$refs.editor2) {
        // @ts-ignore
        this.$refs.editor2.setData(this.data.emailFormat)
      }
    }
  }
